<template>
  <div class="card">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-4">
          <h5 class="text-danger p-3">VESSEL RESPONSE</h5>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">From : </h6>
        </div>
        <div class="col-lg-4">
          <input type="text" class="form-control form-control-sm" :value="vesselResponse.vessel | toUpper"
                 disabled
                 name="instruction_title"
                 id="instruction_title">
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Report Number : </h6>
        </div>
        <div class="col-lg-4">
          <h6>{{!!vesselResponse.report ? vesselResponse.report.code : ''}} - {{!!vesselResponse.report ? vesselResponse.report.title : ''}}</h6>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Job Completion :</h6>
        </div>
        <div class="col-lg-4" >
          <h6 class="btn btn-xs text-white" :class="{
                                    'bg-danger':vesselResponse.cancelled_at,
                                    'bg-574e4e':!vesselResponse.cancelled_at && vesselResponse.job_status_id ==1,
                                    'bg-056cb4':!vesselResponse.cancelled_at && vesselResponse.job_status_id ==2,
                                    'bg-42b983':!vesselResponse.cancelled_at && vesselResponse.job_status_id ==3,
                                    }"
          >
            {{ vesselResponse.cancelled_at ? 'CANCELLED' : vesselResponse.job_status | toUpper }}
          </h6>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Job Done :</h6>
        </div>
        <div class="col-lg-8" >
          <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="vesselResponse.response || '' " />
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-2">
          <h6 class="font-weight-bold">Attachments : </h6>
        </div>
        <div class="col-lg-10 text-left">
          <div class="row">
            <div class="col-2"  v-for="attachment in instructionAttachments">
              <other-comp @viewOther="viewVesselAttachment(attachment)"
                          :no-delete="true"
                          :attachment="attachment" attachment-name="name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Acknowledgement : </h6>
        </div>
        <div class="col-lg-8" >
          <div class="row" v-if="!vesselResponse.cancelled_at">
            <div class="col-auto">
              <select class="form-control form-control-sm" v-model="vesselResponse.acknowledgement_status_id"
                      name="job-status" id="job-status">
                <option :value="ackStatus.id" v-for="ackStatus in acknowledgementStatuses">{{ackStatus.name}}</option>
              </select>
            </div>
            <div class="col-auto" v-if="isVesselResponseModified">
              <button class="btn btn-sm btn-success" :disabled="vesselResponse.cancelled_at" @click.prevent="sendAcknowledgementStatus">
                SAVE
              </button>
            </div>
          </div>
          <h6 class="btn btn-xs bg-danger text-white" v-else> CANCELLED </h6>
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Acknowledgement Status Updated By : </h6>
        </div>
        <div class="col-lg-4" >
          <input type="text" class="form-control form-control-sm" :value="vesselResponse.ack_status_updated_by | toUpper"
                 disabled
                 name="ack_status_updated_by"
                 id="ack_status_updated_by">
        </div>
      </div>
      <div class="row mx-2 align-items-center mb-2">
        <div class="col-lg-2">
          <h6 class="font-weight-bold">Comments : </h6>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-10">
              <template v-if="!!vesselResponse">
                <CIComment :key="vesselResponse.id" :vessel-response="vesselResponse" v-if="!!vesselResponse.id" :ciVesselId="vesselResponse.id"></CIComment>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OtherComp from "@/components/elements/OtherComp.vue";
import {CI_VESSEL_INDEX} from "@/services/ConstantService";
import axios from "axios";
import {FileService} from "@/services/FileService";
import {AlertService} from "@/services/AlertService";
import CIComment from "@/components/modules/ci/CIComment.vue";
import ObjectMixin from "@/mixins/ObjectMixin";

export default {
  name: "MasterCompanyInstructionResponse",
  components: {CIComment, OtherComp},
  data() {
    return {
      vesselResponse: {},
      report : {},
      tempResponse:{},
      isVesselResponseModified:false,
    }
  },
  mixins:[ObjectMixin],
  computed:{
    ...mapGetters(['instructionAttachments','acknowledgementStatuses'])
  },
  methods: {
    ...mapActions(['getVesselResponse','getVesselAttachments',
      'getAcknowledgementStatuses',
      'sendVesselResponse']),
    async viewVesselAttachment(attachment) {
      swal.fire({
        title: 'Please wait',
      });
      swal.showLoading();
      const url= [CI_VESSEL_INDEX,attachment.referencable_id,'attachment',attachment.id].join('/')
      const response = await axios.get(url ,{responseType: 'blob'})

      if (response && response.status == 200) {
        if (attachment.extension === 'pdf' || attachment.mime_type.includes("image")) {
          await FileService.viewDocument(response.data,attachment)
        }
        else {
          await FileService.downloadDocument(response.data,attachment,'name');
        }
        swal.close();
      }
      else {
        AlertService.errorAlert('File not exists',"View Vessel Attachment")
      }
    },
    async sendAcknowledgementStatus(){
      if(await AlertService.confirmUpdateAlert()){
        const vesselParams={
          id:this.vesselResponse.id,
          acknowledgement_status_id:this.vesselResponse.acknowledgement_status_id,
        }
        const response =await this.sendVesselResponse(vesselParams)
        if(response) {
          AlertService.successAlert('Acknowledgement Status Updated Successfully','Send Acknowledgement Status')
          const response = await this.getVesselResponse({id: this.$route.query.id})
          await this.getVesselAttachments({id: this.$route.query.id})
          this.vesselResponse=Object.assign({},response)
          this.tempResponse=Object.assign({},response)
          this.isVesselResponseModified=false;
        }
      }
    },
  },
  async created() {
    const response = await this.getVesselResponse({id: this.$route.query.id})
    await this.getVesselAttachments({id: this.$route.query.id})
    await this.getAcknowledgementStatuses();
    this.vesselResponse=Object.assign({},response)
    this.tempResponse=Object.assign({},response)
  },
  watch:{
    'vesselResponse.acknowledgement_status_id'(){
      this.isVesselResponseModified=this.dataDiff(this?.tempResponse ?? null,this.vesselResponse ?? null)
    },
  }
}
</script>

<style scoped>

</style>
